<template>
  <Fragment>
    <v-btn @click="$router.back()">
      <v-icon> mdi-arrow-left </v-icon>
    </v-btn>
    <DataTableComponent
      :headers="headers"
      :items="registrosFondos.data"
      :show_loading="registrosFondos.isLoading"
      :total_registros="total"
      v-models:pagina="paginaActual"
      v-models:select="registrosPorPagina"
      @paginar="manejarPaginacionRegistros"
    >
      <template v-slot:[`item.fecha`]="{ item }">
        {{ formatDate(item.fecha, "DD/MM/YYYY") }}
      </template>
      <template v-slot:[`item.monto`]="{ item }">
        ${{ Number(item.monto || 0).toFixed(2) }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip label>
          {{ item.estadoFondos.nombre }}
        </v-chip>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <div class="d-flex justify-center align-center" style="gap: 8px">
          <template
            v-if="
              (esAdministradorInstitucional || esFiscalizador) &&
              item.estadoFondos.id !== 1
            "
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click.stop="(dialogoDocumentos = true), (idCompra = item.id)"
                  :disabled="registrosFondos.isLoading"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  icon
                >
                  <v-icon>mdi-file-cabinet</v-icon>
                </v-btn>
              </template>
              <span>Ver documentos</span>
            </v-tooltip>
          </template>
        </div>
      </template>
    </DataTableComponent>
    <v-dialog v-model="dialogoDocumentos">
      <v-card>
        <v-card-actions>
          <v-btn
            block
            @click="(dialogoDocumentos = false), (idCompra = null), (path = ''), ($refs.pdf.limpiar())"
            >Cerrar</v-btn
          >
        </v-card-actions>
        <v-card-title> Documentos de la compra en línea </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-card outline>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      v-for="(documento, index) in documentos"
                      :key="index"
                    >
                      <v-btn
                        block
                        class="secondary py-2 btn-documento"
                        @click.stop="
                          (nombreDocumento =
                            documento.CtlDocumentosCompraLinea.nombre),
                            $refs.pdf.obtenerArchivo(
                              documento.documento,
                              'compraEnLinea'
                            )
                        "
                      >
                        {{ documento.CtlDocumentosCompraLinea.nombre }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="8">
              <v-card outline>
                <v-card-title>
                  {{ nombreDocumento }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12">
                      <pdfModalViejo ref="pdf" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </Fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { formatDate } from "@/utils/datetime";
import pdfModalViejo from "@/components/PdfModalClienteViejo.vue";
import {
  createPageable,
  togglePageable,
  setPageableResponse,
} from "@/utils/loadable";

export default {
  name: "revisionRegistrosFondoCompraEnLinea",
  components: {
    DataTableComponent,
    Fragment,
    pdfModalViejo,
  },
  data: () => ({
    registrosFondos: createPageable([], 10),
    paginaActual: 1,
    registrosPorPagina: 10,
    idFondo: null,
    idCompra: null,
    filtroEstado: null,
    dialogoDocumentos: false,
    documentos: [],
    headersDocumentos: [
      {
        text: "Tipo de documento",
        value: "CtlDocumentosCompraLinea.nombre",
      },
      {
        text: "Acciones",
        value: "descargar",
      },
    ],
    dialogoDocumentoReview: false,
    disk: "compraEnLinea",
    path: "",
    nombreDocumento: "",
  }),
  computed: {
    total() {
      return this.registrosFondos.pagination.total_rows;
    },
    esAdministradorInstitucional() {
      return this.haveRoles(
        [
          "ROLE_ADMINISTRADOR_INSTITUCIONAL_REVISION_REGISTROS_FONDO_COMPRA_EN_LINEA_VIEW",
        ],
        true
      );
    },
    esFiscalizador() {
      return this.haveRoles(
        ["ROLE_SUPERVISOR_REVISION_REGISTROS_FONDO_COMPRA_EN_LINEA"],
        true
      );
    },
    headers() {
      const headers = [
        {
          align: "center",
          text: "Nombre proveedor",
          value: "nombre_proveedor",
          sortable: false,
        },
        {
          align: "center",
          text: "Fecha de documento",
          value: "fecha",
          sortable: false,
        },
        {
          align: "center",
          text: "Área solicitante",
          value: "area_solicitante",
          sortable: false,
        },
        {
          align: "center",
          text: "Observación",
          value: "observacion",
          sortable: false,
        },
        {
          align: "center",
          text: "Monto ($)",
          value: "monto",
          sortable: false,
        },
        {
          align: "center",
          text: "Unidad",
          value: "EmpleadoUnidad.Unidad.nombre",
          sortable: false,
        },
      ];

      if (this.esAdministradorInstitucional || this.esFiscalizador) {
        headers.push({
          align: "center",
          text: "Acciones",
          value: "acciones",
          sortable: false,
        });
      }
      return headers;
    },
  },
  methods: {
    formatDate,
    manejarPaginacionRegistros(paginacion) {
      const { pagina, cantidad_por_pagina } = paginacion;
      this.paginaActual = pagina;
      this.registrosPorPagina = cantidad_por_pagina;
      this.cargarRegistros();
    },
    async cargarRegistros() {
      togglePageable(this.registrosFondos);
      const filtros = {
        pagination: true,
        per_page: this.registrosPorPagina,
        page: this.paginaActual,
        estado: this.filtroEstado,
      };

      const { data, headers } =
        await this.services.CompraEnLinea.getListRegistrosFondo(
          this.idFondo,
          filtros
        );
      setPageableResponse(this.registrosFondos, data, headers);
    },
  },
  watch: {
    filtroEstado() {
      this.paginaActual = 1;
      this.cargarRegistros();
    },
    idCompra: {
      handler: async function (val) {
        if (val) {
          const { data } = await this.services.CompraEnLinea.cargarDocumentos(
            val
          );
          this.documentos = data;
        }
      },
    },
  },
  created() {
    this.idFondo = this.$route.params.id_fondo;
    this.cargarRegistros();
  },
};
</script>

<style scoped>
.admin-fondos-compra-en-linea-table >>> table thead tr,
.admin-fondos-compra-en-linea-table >>> table tbody tr td {
  background-color: #fff !important;
}

.admin-fondos-compra-en-linea-table >>> table thead tr th:last-child,
.admin-fondos-compra-en-linea-table >>> table tbody tr td:last-child {
  position: sticky;
  right: 0;
}

:deep(.btn-documento) {
  padding: 9px 16px !important;
  max-width: 100%; 
  height: auto !important
}

:deep(.btn-documento .v-btn__content) {
  max-width: 100%;
  word-break: break-word;
  overflow: hidden;
  white-space: normal;
}

</style>