var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Fragment',[_c('v-btn',{on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1),_c('DataTableComponent',{directives:[{name:"models",rawName:"v-models:pagina",value:(_vm.paginaActual),expression:"paginaActual",arg:"pagina"},{name:"models",rawName:"v-models:select",value:(_vm.registrosPorPagina),expression:"registrosPorPagina",arg:"select"}],attrs:{"headers":_vm.headers,"items":_vm.registrosFondos.data,"show_loading":_vm.registrosFondos.isLoading,"total_registros":_vm.total},on:{"paginar":_vm.manejarPaginacionRegistros},scopedSlots:_vm._u([{key:`item.fecha`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha, "DD/MM/YYYY"))+" ")]}},{key:`item.monto`,fn:function({ item }){return [_vm._v(" $"+_vm._s(Number(item.monto || 0).toFixed(2))+" ")]}},{key:`item.estado`,fn:function({ item }){return [_c('v-chip',{attrs:{"label":""}},[_vm._v(" "+_vm._s(item.estadoFondos.nombre)+" ")])]}},{key:`item.acciones`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"gap":"8px"}},[(
            (_vm.esAdministradorInstitucional || _vm.esFiscalizador) &&
            item.estadoFondos.id !== 1
          )?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.registrosFondos.isLoading,"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();(_vm.dialogoDocumentos = true), (_vm.idCompra = item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-cabinet")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver documentos")])])]:_vm._e()],2)]}}],null,true)}),_c('v-dialog',{model:{value:(_vm.dialogoDocumentos),callback:function ($$v) {_vm.dialogoDocumentos=$$v},expression:"dialogoDocumentos"}},[_c('v-card',[_c('v-card-actions',[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){(_vm.dialogoDocumentos = false), (_vm.idCompra = null), (_vm.path = ''), (_vm.$refs.pdf.limpiar())}}},[_vm._v("Cerrar")])],1),_c('v-card-title',[_vm._v(" Documentos de la compra en línea ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"outline":""}},[_c('v-card-text',[_c('v-row',_vm._l((_vm.documentos),function(documento,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"secondary py-2 btn-documento",attrs:{"block":""},on:{"click":function($event){$event.stopPropagation();(_vm.nombreDocumento =
                          documento.CtlDocumentosCompraLinea.nombre),
                          _vm.$refs.pdf.obtenerArchivo(
                            documento.documento,
                            'compraEnLinea'
                          )}}},[_vm._v(" "+_vm._s(documento.CtlDocumentosCompraLinea.nombre)+" ")])],1)}),1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{attrs:{"outline":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.nombreDocumento)+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('pdfModalViejo',{ref:"pdf"})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }